<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-5" style="height: 62px; width: 100%">
          <!-- Left-aligned text field -->
          <div class="d-inline-flex align-center" style="height: 100%">
            <v-text-field
              v-model="search"
              class="ml-5 custom-search-perm"
              outlined
              clearable
              dense
              hide-details
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
          </div>

          <!-- Right-aligned buttons -->
          <div class="d-inline-flex align-center ml-auto" style="height: 100%">
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="$emit('fetchTemplates', { options: options, search: search.length > 1 ? search : '', resetPage: true })"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="roles.includes('TEMPLATE_CREATOR') || roles.includes('SUPER_USER')"
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'templateCreate', params: { lang: $lang.current_lang } }"
            >
              {{ $lang.actions.create }}
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.status.YES : $lang.status.NO }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex justify-end" >
          <!-- Separate Edit Button -->
          <v-btn
            text
            color="primary"
            :to="{ name: 'templateEdit', params: { id: item.id } }"
          >
            <v-icon small class="mr-1">mdi-lead-pencil</v-icon>
            {{ $lang.actions.edit }}
          </v-btn>

          <!-- Button with mdi-dots-vertical Icon -->
          <v-menu class="custom-edit-menu" offset-y nudge-left="95" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                variant="text"
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <!-- Menu with actions -->
            <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid ;">
              <v-list>
                <!-- Copy Create button -->
                <v-list-item
                  v-if="roles.includes('TEMPLATE_CREATOR') || roles.includes('SUPER_USER')"
                  link
                  class="text--secondary font-weight-light"
                  @click="$emit('copyCreate', item)"
                >
                  {{ $lang.actions.copyCreate }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      roles: [],
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings', 'userRoles'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchTemplates', { options: this.options, search: this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchTemplates', { options: this.options, search: val, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchTemplates', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search.length > 1 ? this.search : '', resetPage: true } })
        this.savePreFill()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchTemplates', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.templateList) {
        this.search = preFill.templateList.search
        this.activeOnly = preFill.templateList.activeOnly
        this.options = preFill.templateList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
      } else {
        this.$emit('fetchTemplates', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      }
    } else {
      this.$emit('fetchTemplates', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.templateList) preFill.templateList = {
          search: '',
          activeOnly: true,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.templateList.search = this.search
        preFill.templateList.activeOnly = this.activeOnly
        preFill.templateList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
